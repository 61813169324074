import * as React from 'react';
import { default as MuiDialog } from '@material-ui/core/Dialog';
import FocusTrap from 'focus-trap-react';
import { DialogProps } from './Dialog.types';
import { DialogTitle } from '../DialogTitle';

const DialogFocus = React.forwardRef((props: DialogProps, ref: React.Ref<HTMLDivElement>) => {
  const { children, hasTitle } = props;

  return (
    <FocusTrap
      focusTrapOptions={{
        clickOutsideDeactivates: true,
        initialFocus: hasTitle ? '.bb-h1-element' : '.bb-dialog-content',
        fallbackFocus: '.bb-dialog-content',
      }}
    >
      <div ref={ref}>
        {children}
      </div>
    </FocusTrap>
  );
});

export const Dialog = React.forwardRef((
  props: DialogProps,
  ref: React.Ref<unknown>,
) => {
  const { children, hasTitle, isAlert, open, ...other } = props;
  const renderChildren = () => React.Children.map(
    children, (child) => {
      if (React.isValidElement(child)) {
        const elementChild: React.ReactElement<any> = child;
        if (elementChild.type === DialogTitle) {
          return React.cloneElement(elementChild, {
            isAlert,
          });
        }
        return elementChild;
      }
      return children;
    },
  );

  return (
    <>
      <MuiDialog
        disableAutoFocus
        disableEnforceFocus
        maxWidth="md"
        scroll="body"
        PaperProps={{
          elevation: 0,
          role: isAlert ? 'alertdialog' : 'dialog',
        }}
        TransitionProps={{
          role: undefined,
        } as any}
        open={open}
        ref={ref}
        {...other}
      >
        <DialogFocus
          open={open}
          hasTitle={hasTitle}
        >
          {renderChildren()}
        </DialogFocus>
      </MuiDialog>
    </>
  );
});

DialogFocus.defaultProps = {
  hasTitle: true,
};

export default Dialog;
